<!--footer section -->
<footer [ngClass]="class">
    <div class="dark-layout" *ngIf="mainFooter">
        <div class="container">
            <section class="section-b-space border-b">
                <div class="row footer-theme2">
                    <div class="col-lg-3">
                        <div class="footer-title footer-mobile-title">
                            <h4>about</h4>
                        </div>
                        <div class="footer-contant">
                            <div class="footer-logo" id="footerlogo">
                                <img [src]="themeLogo"  alt="logo">
                            </div>
                            <p>OUR SAUNA PRODUCTS AND ACCESSORIES ARE SOLD CANADA WIDE</p>
                        </div>
                    </div>
                    <div class="col-lg-6 subscribe-wrapper">
                        <div class="subscribe-block">
                            <h2>newsletter</h2>
                            <form >
                                <div class="form-group">
                                    <input type="text" class="form-control" id="exampleFormControlInput3" placeholder="Enter your email">
                                    <button type="submit" class="btn btn-solid ">subscribe</button>
                                </div>
                            </form>
                        </div>
                    </div>
                    <div class="col-lg-3">
                        <div class="footer-title">
                            <h4>CONTACT US</h4>
                        </div>
                        <div class="footer-contant">
                            <p>If you have any questions, comments or concerns, please feel free to email us at <a href="mailto:info@sauna.ca">info@sauna.ca</a>.
                                Thank you for visiting our website!</p>
                            <br />
                            <ul class="contact-details">
                                <li>Call Us: (450) 689-2422</li>
                                <li>Toll free: 1 (855) 689-2422</li>
                                <li>Email Us: <a href="mailto:info@sauna.ca">info@sauna.ca</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    </div>
<!--    <div class="dark-layout" *ngIf="subFooter">-->
<!--        <div class="container">-->
<!--            <section class="small-section">-->
<!--                <div class="row footer-theme2">-->
<!--                    <div class="col p-set">-->
<!--                        <div class="footer-link">-->
<!--                            <div class="sub-title">-->
<!--								<div class="footer-title">-->
<!--									<h4>Categories</h4>-->
<!--								</div>-->
<!--								<div class="footer-contant">-->
<!--									<ul>-->
<!--										<li><a href="javascript:void(0)">Womens Fashion</a></li>-->
<!--										<li><a href="javascript:void(0)">Mens Fashion</a></li>-->
<!--										<li><a href="javascript:void(0)">Kids Fashion</a></li>-->
<!--										<li><a href="javascript:void(0)">Featured</a></li>-->
<!--									</ul>-->
<!--								</div>-->
<!--							</div>-->
<!--                        </div>-->
<!--                        <div class="footer-link-b">-->
<!--                            <div class="sub-title">-->
<!--								<div class="footer-title">-->
<!--									<h4>why we choose</h4>-->
<!--								</div>-->
<!--								<div class="footer-contant">-->
<!--									<ul>-->
<!--										<li><a href="javascript:void(0)">shipping & return</a></li>-->
<!--										<li><a href="javascript:void(0)">secure shopping</a></li>-->
<!--										<li><a href="javascript:void(0)">gallary</a></li>-->
<!--										<li><a href="javascript:void(0)">affiliates</a></li>-->
<!--										<li><a href="javascript:void(0)">contacts</a></li>-->
<!--									</ul>-->
<!--								</div>-->
<!--							</div>-->
<!--                        </div>-->
<!--                    </div>-->
<!--                </div>-->
<!--            </section>-->
<!--        </div>-->
<!--    </div>-->
    <div class="sub-footer darker-subfooter">
        <div class="container">
            <div class="row">
                <div class="col-xl-6 col-md-6 col-sm-12">
                  <div class="footer-end">
                    <p><i class="fa fa-copyright" aria-hidden="true"></i> {{ today | date:'y' }} SAUNA.CA A DIVISION OF SAUNA SPECIALIST INC.</p>
                  </div>
                </div>
                <div class="col-xl-6 col-md-6 col-sm-12">
                  <div class="payment-card-bottom">
                      <p>DESIGNED AND DEVELOPED BY NAETHRA TECHNOLOGIES</p>

                  </div>
                </div>
            </div>
        </div>
    </div>
</footer>
<!--footer section end -->