import { Injectable } from '@angular/core';
import {map, Observable} from "rxjs";
import {HttpClient} from "@angular/common/http";

@Injectable({
  providedIn: 'root'
})
export class SaunaService {

  constructor(private http: HttpClient,) { }

  getAllProducts() {
    return this.http.get<any>('https://commercial.ntplstaging.com/backend/getAllProduct.php').pipe(
        map(data => {
          return data
        })
    );
  }
}
