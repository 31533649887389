import { Component, OnInit, Input } from '@angular/core';
import { NewProductSlider } from '../../../data/slider';
import { Product } from '../../../classes/product';
import { ProductService } from '../../../services/product.service';
import {SaunaService} from "../../../../services/sauna.service";

@Component({
  selector: 'app-product-box-vertical-slider',
  templateUrl: './product-box-vertical-slider.component.html',
  styleUrls: ['./product-box-vertical-slider.component.scss']
})
export class ProductBoxVerticalSliderComponent implements OnInit {

  @Input() title: string = 'New Product'; // Default
  @Input() type: string = 'Electric Heaters'; // Default Fashion

  public products = [];

  public NewProductSliderConfig: any = NewProductSlider;

  constructor(public productService: ProductService, public saunaService: SaunaService) {
    // this.getLiveProducts();
  }

  ngOnInit(): void {
  }

  // getLiveProducts() {
  //   this.saunaService.getAllProducts().subscribe((data) => {
  //     const final = Array.from(new Set(data.map(a => a.Category.toUpperCase())));
  //     this.products = data.filter(item => item.Category == this.type)
  //     console.log(final);
  //   }, e => {
  //     console.log(e.error.message);
  //   });
  // }

}
