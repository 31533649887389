import { Component, OnInit } from '@angular/core';
import { NavService, Menu } from '../../services/nav.service';
import { Router } from '@angular/router';
import {SaunaService} from "../../../services/sauna.service";

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit {

  public menuItems: Menu[];

  saunaMenu: any[] = [
    { path: '/shopping', title: 'Home', type: 'link' },
  ];
  public liveProduct = [];
  public productCollections: any[] = [];

  constructor(private router: Router, public navServices: NavService, private saunaService: SaunaService,) {
    this.navServices.items.subscribe(menuItems => this.menuItems = menuItems );
    this.router.events.subscribe((event) => {
      this.navServices.mainMenuToggle = false;
    });
  }

  ngOnInit(): void {
    const localProduct = JSON.parse(localStorage.getItem('saunaProducts'));
    if (localProduct === null) {
      this.getLiveProducts();
    } else {
      this.productCollections = Array.from(new Set(localProduct.map(a => a.Category)));
      this.liveProduct = localProduct;
      this.productCollections.forEach(f2 => {
        let b = [];
        this.liveProduct.forEach(a => {
          if (a.Category === f2) {
            b.push({
              title : a.subCategory,
              type : 'link',
              brand : a.Category.trim().toString().replaceAll(' ', '-'),
              path : a.subCategory.trim().toString().replaceAll(' ', '-')
            })
          }
        });
        const objRes=b.filter((v,i,s)=>s.findIndex(v2=>['title','type', 'brand', 'path'].every(k=>v2[k]===v[k]))===i);
        this.sorting(objRes);
        this.saunaMenu.push({
          title: f2,
          type: 'sub', megaMenu: false, active: false,
          children: objRes,
        });
      });
    }
  }

  /*
    --- MENU LIST SORT ASC
    --- Pass the Array value of menu children
  */
  sorting(objRes) {
    return objRes.sort((a, b) => {
      let fa = a.title.toLowerCase(),
          fb = b.title.toLowerCase();
      if (fa < fb) {
        return -1;
      }
      if (fa > fb) {
        return 1;
      }
      return 0;
    });
  }

  getLiveProducts() {
    this.saunaService.getAllProducts().subscribe((data) => {
      this.liveProduct = data;
      localStorage.setItem('saunaProducts', JSON.stringify(data));
      this.productCollections = Array.from(new Set(data.map(a => a.Category)));
    }, e => {
      console.log(e.error.message);
    });
  }

  mainMenuToggle(): void {
    this.navServices.mainMenuToggle = !this.navServices.mainMenuToggle;
  }

  // Click Toggle menu (Mobile)
  toggletNavActive(item) {
    item.active = !item.active;
    this.router.navigate(['shopping/brandWise/' + item.brand + '/' + item.path]);
  }

}
