<div class="main-navbar">
  <div id="mainnav">
    <div class="toggle-nav" (click)="mainMenuToggle()">
      <i class="fa fa-bars sidebar-bar"></i>
    </div>
    <ul class="nav-menu" [class.opennav]="navServices?.mainMenuToggle">
      <li class="back-btn">
        <div class="mobile-back text-end" (click)="mainMenuToggle()">
          <span>Back</span>
          <i class="fa fa-angle-right ps-2" aria-hidden="true"></i>
        </div>
      </li>
      <li *ngFor="let menuItem of saunaMenu" [class.mega-menu]="menuItem.megaMenu">

        <a href="javascript:void(0)" class="nav-link" *ngIf="menuItem.type==='sub'" (click)="toggletNavActive(menuItem)">{{menuItem.title}}
          <span class="sub-arrow" *ngIf="menuItem.children"></span>
        </a>

        <a [routerLink]="menuItem.path" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" class="nav-link" *ngIf="menuItem.type==='link'">{{menuItem.title}}
          <span class="sub-arrow" *ngIf="menuItem.children"></span>
        </a>

        <a href="{{menuItem.path}}" class="nav-link" *ngIf="menuItem.type==='extLink'">{{menuItem.title}}
          <span class="sub-arrow" *ngIf="menuItem.children"></span>
        </a>

        <a href="{{menuItem.path}}" class="nav-link" *ngIf="menuItem.type==='extTabLink'">{{menuItem.title}}
          <span class="sub-arrow" *ngIf="menuItem.children"></span>
        </a>

        <ul class="nav-submenu" [class.opensubmenu]="menuItem.active" *ngIf="menuItem.children">
          <li *ngFor="let childrenItem of menuItem.children">

            <!-- SUB link -->
            <a href="javascript:void(0)" *ngIf="childrenItem.type==='sub'" (click)="toggletNavActive(childrenItem)">{{childrenItem.title}}
              <span class="sub-arrow" *ngIf="childrenItem.children"></span>
            </a>
            <!-- // SUB link -->

            <a href="javascript:void(0)" *ngIf="childrenItem.type==='link'" (click)="toggletNavActive(childrenItem)">
<!--              [routerLink]="childrenItem.path" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}"-->
              {{childrenItem.title}}
              <span class="sub-arrow" *ngIf="childrenItem.children"></span>
            </a>
            <a href="{{childrenItem.path}}" *ngIf="childrenItem.type==='extLink' ">{{childrenItem.title}}
              <span class="sub-arrow" *ngIf="childrenItem.children"></span>
            </a>
            <a href="{{childrenItem.path}}" target="_blank" *ngIf="childrenItem.type==='extTabLink' ">{{childrenItem.title}}
              <span class="sub-arrow" *ngIf="childrenItem.children"></span>
            </a>
            <ul *ngIf="childrenItem.children" [class.opensubchild]="childrenItem.active" class="nav-sub-childmenu">
              <li *ngFor="let childrenSubItem of childrenItem.children">
                <a [routerLink]="childrenSubItem.path" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" *ngIf="childrenSubItem.type==='link' ">{{childrenSubItem.title}}</a>
                <a href="{{childrenSubItem.path}}" *ngIf="childrenSubItem.type==='extLink' ">{{childrenSubItem.title}}</a>
                <a href="{{childrenSubItem.path}}" target="_blank" *ngIf="childrenSubItem.type==='extTabLink' ">{{childrenSubItem.title}}</a>
              </li>
            </ul>
          </li>
        </ul>
        <div class="mega-menu-container" *ngIf="menuItem.megaMenu && menuItem.children">
          <div class="container">
            <div class="row">
              <div class="col mega-box" *ngFor="let childrenItem of menuItem.children">
                <div class="link-section">
                  <div class="menu-title">
                    <h5>{{childrenItem.title}}
                      <span class="sub-arrow" *ngIf="childrenItem.children"></span>
                    </h5>
                  </div>
                  <div class="menu-content opensubmegamenu">
                    <ul *ngIf="childrenItem.children">
                      <li *ngFor="let childrenSubItem of childrenItem.children">
                        <a href="javascript:void(0)" *ngIf="childrenSubItem.type==='sub'">{{childrenSubItem.title}}</a>
                        <a [routerLink]="childrenSubItem.path" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" *ngIf="childrenSubItem.type==='link' ">{{childrenSubItem.title}}</a>
                        <a href="{{childrenSubItem.path}}" *ngIf="childrenSubItem.type==='extLink' ">{{childrenSubItem.title}}</a>
                        <a href="{{childrenSubItem.path}}" target="_blank" *ngIf="childrenSubItem.type==='extTabLink' ">{{childrenSubItem.title}}</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </li>
    </ul>
  </div>
</div>