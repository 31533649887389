import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { ShopComponent } from './shop/shop.component';
import { PagesComponent } from './pages/pages.component';
import { ElementsComponent } from './elements/elements.component';
import {MainLayoutComponent} from "./shopping/main-layout/main-layout.component";

const routes: Routes = [
  {
    path : '', component : MainLayoutComponent,
    children : [
      {path : '', redirectTo : 'shopping', pathMatch : 'full'},
      {path : 'shopping', loadChildren: () => import('./shopping/shopping.module').then(m => m.ShoppingModule) },
    ],
  },

  // {
  //   path: '',
  //   redirectTo: 'shopping',
  //   pathMatch: 'full'
  // },
  // {
  //   path: 'shopping',
  //   loadChildren: () => import('./shopping/shopping.module').then(m => m.ShoppingModule)
  // },

  {
    path: 'pages',
    component: PagesComponent,
    loadChildren: () => import('./pages/pages.module').then(m => m.PagesModule)
  },
  {
    path: '**',
    redirectTo: 'home/fashion',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    useHash: false,
    anchorScrolling: 'enabled',
    scrollPositionRestoration: 'enabled',
    relativeLinkResolution: 'legacy',
    onSameUrlNavigation: 'reload'
})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
