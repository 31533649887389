<div *ngIf="!loader">
  <div class="img-wrapper">
    <div class="lable-block">
      <span class="lable3" *ngIf="product.new">new</span>
      <span class="lable4" *ngIf="product.sale">on sale</span>
    </div>
    <div class="front">
      <a [routerLink]="['/shopping/product/'+ product.Category.trim().replace(' ', '-') +'/' + product.ShortEnglishDescription.toString().trim().replaceAll(' ', '-')]">
        <img src="assets/images/product/sample_product.png"
          class="img-fluid lazy-loading" 
          alt="{{ product.ShortEnglishDescription }}" />
      </a>
    </div>
    <div class="back" *ngIf="onHowerChangeImage">
      <a [routerLink]="['/product/'+ product.Category.trim().replace(' ', '-') +'/' + product.ShortEnglishDescription.trim().toString().replaceAll(' ', '-')]">
        <img src="assets/images/product/sample_product.png" class="img-fluid lazy-loading" alt="{{ product.ShortEnglishDescription }}">
      </a>
    </div>
    <div class="cart-info cart-wrap">
      <a href="javascript:void(0)" title="Add to cart" (click)="CartModal.openModal(product)"
        *ngIf="cartModal">
        <i class="ti-shopping-cart"></i>
      </a>
      <a href="javascript:void(0)" title="Add to cart" (click)="addToCart(product)"
        *ngIf="!cartModal">
        <i class="ti-shopping-cart"></i>
      </a>
      <a href="javascript:void(0)" title="Add to Wishlist" (click)="addToWishlist(product)">
        <i class="ti-heart" aria-hidden="true"></i>
      </a>
      <a href="javascript:void(0)" title="Quick View" (click)="QuickView.openModal()">
        <i class="ti-search" aria-hidden="true"></i>
      </a>
      <a href="javascript:void(0)" title="Compare" (click)="addToCompare(product)">
        <i class="ti-reload" aria-hidden="true"></i>
      </a>
    </div>
  </div>
  <div class="product-detail">
    <div>
      <bar-rating [rate]="5" [readOnly]="true"></bar-rating>
      <a [routerLink]="['product/'+ product.Category.trim().replace(' ', '-') +'/' + product.ShortEnglishDescription.toString().trim().replaceAll(' ', '-')]">
        <h6>{{ product?.ShortEnglishDescription | titlecase }}</h6>
      </a>
      <p>{{ product?.EnglishDescription }}</p>
      <h4>
        {{ product?.SalePrice | currency:currency?.currency:'symbol' }}
      </h4>
      <h5 class="avalibility text-success" *ngIf="product.StockCheck !== 'outofstock'"><span>In Stock</span></h5>
      <del class="avalibility text-danger" *ngIf="product.StockCheck === 'outofstock'"><span>Out of Stock</span></del>
<!--      <ul class="color-variant" *ngIf="Color(product?.variants).length">-->
<!--        <li [class]="color" *ngFor="let color of Color(product?.variants)" [ngStyle]="{'background-color': color}"-->
<!--          (click)="ChangeVariants(color, product)">-->
<!--        </li>-->
<!--      </ul>-->
    </div>
  </div>
</div>
<app-skeleton-product-box *ngIf="loader"></app-skeleton-product-box>
<app-quick-view #quickView [product]="product" [currency]="currency"></app-quick-view>
<app-cart-modal #cartModal [product]="product" [currency]="currency" *ngIf="cartModal"></app-cart-modal>

